import React, { useState } from "react";
import { InfoIcon, X } from "lucide-react";

function InfoModal() {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="z-20 ">
      <div className="absolute top-4 left-4">
        <button onClick={openModal}>
          <InfoIcon className="text-white" />
        </button>
      </div>

        <div className={`overflow-scroll absolute w-full flex justify-center z-20 transition-opacity duration-500  ${modalOpen ? "opacity-100 flex" : "opacity-0 hidden"}`}>
             <button
                onClick={closeModal}
                className="fixed right-4 top-4"
              >
          <X  className=" text-gray-800"/>
              </button>
          <div className="bg-white text-gray-800 p-8 rounded shadow-lg max-w-md flex flex-col gap-6">
            <h2 className="text-2xl font-semibold">Upute:</h2>

            <div>
              <h3 className="text-lg font-semibold">Korak 1: Unos Imena</h3>
              <p>Upišite svoje ime kako bi vaše slike bile povezane s vama.</p>
            </div>

            <div>
              <h3 className="text-lg font-semibold">Korak 2: Učitavanje Slika</h3>
              <p>Kliknite na opciju "Start" ili "Nastavi".</p>
              <ol className="list-decimal list-inside ml-4">
                <li>Pronađite ikonu plusa (+) u sredini ekrana.</li>
                <li>Dodirnite ikonu plusa kako biste omogućili odabir slike s vašeg uređaja.</li>
                <li>Odaberite željenu sliku iz svoje galerije slika.</li>
                <li>Potvrdite odabir slike kako biste je učitali u aplikaciju.</li>
                <li>Ponovite ovaj postupak za svih pet slika.</li>
              </ol>
            </div>

            <div>
              <h3 className="text-lg font-semibold">Korak 3: Potvrda Učitavanja Slika</h3>
              <p>Pregledajte učitane slike i provjerite jesu li vam sve zadovoljavajuće.</p>
              <ol className="list-decimal list-inside ml-4">
                <li>Ako želite zamijeniti neku sliku, dodirnite je i odaberite opciju za zamjenu.</li>
                <li>Kada ste zadovoljni svim slikama, potvrdite svoj izbor.</li>
              </ol>
            </div>

            <div>
              <h3 className="text-lg font-semibold">Korak 4: Učitavanje Slika</h3>
              <ol className="list-decimal list-inside ml-4">
              <li>Proces učitavanja će započeti, a na ekranu će se prikazati poruka "Učitavanje..." kako biste znali da je proces u tijeku.</li>
              <li>Molimo pričekajte dok se sve slike uspješno učitaju.</li>
              </ol>

            </div>

            <div>
              <h3 className="text-lg font-semibold">Korak 5: Status Učitavanja i Završetak</h3>
              <ol className="list-decimal list-inside ml-4">
            
              <li>Gornji desni kut ekrana prikazuje status: npr. "3/5" za tri učitane slike od pet.</li>
              <li>Kada uspješno učitate sve pet slika, prikazat će se poruka: "Objavili ste maksimalan broj fotografija!"</li>
              <li>Vaše slike su sada uspješno učitane i pohranjene u album posebnog događaja (vjenčanja).</li>
              </ol>
           
            </div>

            <div className="flex justify-end">
              <button
                onClick={closeModal}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Zatvori
              </button>
            </div>
          </div>
        </div>

    </div>
  );
}

export default InfoModal;
