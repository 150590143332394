import React from 'react';

const ImageCard = ({ src, name, time }) => {
  return (
    <div className='bg-white p-2 relative'>
      <img src={src} alt="a" />
      <p className='font-della text-xl text-center pt-2'>{name}</p>
      <p className='font-della text-md text-center absolute top-0 right-0 bg-elegance text-white'>{time}</p>
    </div>
  );
}

export default ImageCard;
