import React, { useState, useEffect } from "react";
import ImageCard from "./components/imageCard";

function ViewPhotos() {
  const [imageNames, setImageNames] = useState([]);

  useEffect(() => {
    fetch("https://blog.personaliziranipokloni.com/getImages.php")
      .then((response) => response.json())
      .then((data) => setImageNames(data))
      .catch((error) => {
        console.error("Dogodila se greška:", error);
      });
  }, []);

  const imageBaseUrl = "https://blog.personaliziranipokloni.com/uploads/";

  return (
    <div className="bg-elegance p-4 ">
      <div className="grid grid-cols-2 md:grid-cols-6 gap-4">



      <ImageCard
        name='amte'
        time='20:20:21'
        src={`https://placehold.co/1080x1920`}
      />
     <ImageCard
        name='amte'
        time='20:20:21'
        src={`https://placehold.co/1080x1920`}
      />

        

      {imageNames.map((imageName, index) => (
        <ImageCard
          name={imageName.split("-")[2]}
          time={imageName.split("-")[0]}
          src={`${imageBaseUrl}${imageName}`}
        />
      ))}
    </div>
    </div>

  );
}

export default ViewPhotos;
