import React, { useState, useEffect } from "react";
import { PlusCircle } from "lucide-react";
import InfoModal from "./components/infoModal";

function Photos() {
  const [error, setError] = useState("");
  const initialImageCount = localStorage.getItem("ImageCount") || 1;
  const name = localStorage.getItem("Name");
  const id = localStorage.getItem("Id");
  const [imageCount, setImageCount] = useState(Number(initialImageCount));

  useEffect(() => {
    localStorage.setItem("ImageCount", imageCount);
  }, [imageCount]);

  const [uploading, setUploading] = useState(false);

  const handleUpload = async (event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) return;

    setUploading(true);
    setError("");
    const formData = new FormData();
    formData.append("image", selectedFile);
    formData.append("name", name);
    formData.append("id", id);

    try {
      const response = await fetch(
        "https://blog.personaliziranipokloni.com/upload.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        setImageCount((prevCount) => prevCount + 1);
        const responseBody = await response.text();
        console.log(responseBody);
        console.log(response);
      } else {
        setError("Došlo je do greške prilikom uploada slike.");
      }
    } catch (error) {
      setError("Došlo je do greške prilikom slanja zahteva.");
      alert(error);
    }

    setUploading(false);
  };

  return (
    <div className="w-screen h-screen overflow-hidden text-white font-della p-4">
      <InfoModal />

      <div className="absolute top-4 right-4">
        <h2 className="text-2xl">{imageCount}/5</h2>
      </div>

      <div className="w-full h-full flex-col gap-4 flex items-center justify-center p-5">
        {imageCount < 5 && (
          <>
            <label htmlFor="photos">
              <PlusCircle className="h-36 w-36 cursor-pointer" />
            </label>
            <input
              id="photos"
              className="hidden"
              type="file"
              accept="image/*"
              capture="user"
              onChange={handleUpload}
              disabled={uploading}
            />
            {error && <p className="text-red-500">{error}</p>}

            {uploading && <p>Učitavanje...</p>}
          </>
        )}

        {imageCount === 5 && (
          <h1 className="text-3xl pb-10 text-center">
            Objavili ste maksimalan broj fotografija!
          </h1>
        )}
      </div>
    </div>
  );
}

export default Photos;
