import React, { useEffect } from "react";
import "./App.css";

import {  Route, Routes, useNavigate } from "react-router-dom";
import Home from "./pages/Home";
import Photos from "./pages/photos/Photos";
import View from "./pages/viewPhotos/ViewPhotos";

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const id = localStorage.getItem("Id");
    if (window.location.pathname === "/photos" && !id) {
      navigate("/home");
    } else if (window.location.pathname === "/home" && id) {
      navigate("/photos");
    }
  }, [navigate]);

  return (
    <div className="bg-elegance relative ">

      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/photos" element={<Photos />} />
        <Route path="/viewPhotos" element={<View />} />
      </Routes>
    </div>
  );
}

export default App;
